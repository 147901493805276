import { library, dom, config } from '@fortawesome/fontawesome-svg-core';
import { 
    faCircle as faCircleLight,
    faSearch as faSearchLight, 
    faSync as faSyncLight, 
    faGlobe as faGlobeLight, 
    faFileWord as faFileWordLight, 
    faFilePowerpoint as faFilePowerpointLight, 
    faFileImage as faFileImageLight, 
    faFileSpreadsheet as faFileSpreadsheetLight, 
    faFileArchive as faFileArchiveLight, 
    faFilePdf as faFilePdfLight, 
    faFile as faFileLight, 
    faPlayCircle as faPlayCircleLight, 
    faEdit as faEditLight, 
    faSpinner as faSpinnerLight, 
    faUser as faUserLight,
    faSquare as faSquareLight,
    faArrowAltRight as faArrowAltRightLight
} from '@fortawesome/pro-light-svg-icons';
import { 
    faSync as faSyncRegular, 
    faArrowAltRight as faArrowAltRightRegular
 } from '@fortawesome/pro-regular-svg-icons';
import { 
    faFacebookF, 
    faFacebookSquare, 
    faTwitter, 
    faTwitterSquare, 
    faLinkedinIn, 
    faLinkedin, 
    faInstagram, 
    faYoutube 
} from '@fortawesome/free-brands-svg-icons';

import { 
    faDownload,
    faCircle,
} from '@fortawesome/pro-solid-svg-icons';


config.searchPseudoElements = true;
config.keepOriginalSource = false;

library.add(
    // light
    faCircleLight,
    faSearchLight,
    faSyncLight,
    faGlobeLight,
    faFileWordLight,
    faFilePowerpointLight,
    faFileImageLight,
    faFileSpreadsheetLight,
    faFileArchiveLight,
    faFilePdfLight,
    faFileLight,
    faPlayCircleLight,
    faEditLight,
    faSpinnerLight,
    faUserLight,
    faSquareLight,
    faArrowAltRightLight,
    faArrowAltRightRegular,
    faFacebookF, 
    faFacebookSquare, 
    faTwitter, 
    faTwitterSquare, 
    faLinkedinIn, 
    faLinkedin, 
    faInstagram, 
    faYoutube,
    faCircle,
    
    // regular
    faSyncRegular,

    // solid
    faDownload
  
);

dom.i2svg();
dom.watch();
